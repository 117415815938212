import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Notification } from "hds-react";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "how-to-migrate-to-hds-20",
      "style": {
        "position": "relative"
      }
    }}>{`How to migrate to HDS 2.0?`}<a parentName="h1" {...{
        "href": "#how-to-migrate-to-hds-20",
        "aria-label": "how to migrate to hds 20 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Here you will find some useful information to make your transition to HDS 2.0 as smooth as possible.
    </LeadParagraph>
    <p>{`Reading this article will help you understand what you should consider and take into account when you migrate your project to the HDS 2.0. If you are looking for a general overview of changes in version 2.0, please refer to our `}<InternalLink href="/getting-started/hds-2.0/what-is-hds-2.0" mdxType="InternalLink">{`What is HDS 2.0 page.`}</InternalLink></p>
    <h2 {...{
      "id": "developers",
      "style": {
        "position": "relative"
      }
    }}>{`Developers`}<a parentName="h2" {...{
        "href": "#developers",
        "aria-label": "developers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification label="A summary for developers" className="siteNotification" mdxType="Notification">
  <ol>
    <li>Check components that had their API slightly changed (Accordion, Link, and Table)</li>
    <li>Get familiar with updated typography tokens</li>
    <li>Check your tests to see if any of the visual changes have caused them to break</li>
    <li>Learn about new content width changes</li>
  </ol>
    </Notification>
    <p>{`Before updating, we recommend to take a look at the `}<InternalLink href="/getting-started/hds-2.0/what-is-hds-2.0" mdxType="InternalLink">{`What is HDS 2.0 page`}</InternalLink>{` and `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.0.0" mdxType="ExternalLink">{`the official 2.0 changelog`}</ExternalLink>{`. Pay extra attention to the "Breaking changes" section of the changelog. This helps you to get an understanding of what has been changed and what kind of effects updating may have in the implementation.`}</p>
    <p>{`Start the migration process by updating your project's libraries to the newest HDS version. After this, keep reading below to get familiar with all major changes and how you may need to react to those.`}</p>
    <h3 {...{
      "id": "changes-to-component-apis",
      "style": {
        "position": "relative"
      }
    }}>{`Changes to component APIs`}<a parentName="h3" {...{
        "href": "#changes-to-component-apis",
        "aria-label": "changes to component apis permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`There are only a few breaking API changes in the HDS 2.0. `}</p>
    <p>{`First, we have removed two (2) theme properties. These variables are not available for customisation anymore.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Removed theme variable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><InternalLink href="/components/accordion" mdxType="InternalLink">{`Accordion`}</InternalLink></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--button-border-color-hover`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><InternalLink href="/components/table" mdxType="InternalLink">{`Table`}</InternalLink></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--background-color`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Removed theme variables]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`Secondly, there has been one change to a React component's API. The default value of the Link component's property `}<inlineCode parentName="p">{`size`}</inlineCode>{` has been changed from `}<inlineCode parentName="p">{`S`}</inlineCode>{` to `}<inlineCode parentName="p">{`M`}</inlineCode>{`. If you have used the Link component with the old default size (small), from now on you need to specify that explicitly by using the property `}<inlineCode parentName="p">{`size="S"`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "updated-typography-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Updated typography tokens`}<a parentName="h3" {...{
        "href": "#updated-typography-tokens",
        "aria-label": "updated typography tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS typography tokens have been changed to match the new Hel.fi visual identity. The following font size tokens have been changed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Old font-size value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`New font-size value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52px (3.25rem)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`48px (3.0rem)`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--fontsize-heading-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36px (2.25rem)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`32px (2.0rem)`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Changed typography tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`We have also added two (2) new font size tokens; `}<inlineCode parentName="p">{`--fontsize-heading-xxl`}</inlineCode>{` (64px) and `}<inlineCode parentName="p">{`--fontsize-heading-xl-mobile`}</inlineCode>{` (40px). The `}<inlineCode parentName="p">{`--fontsize-heading-xxl`}</inlineCode>{` token is meant to be used as the size of a heading level 1. The `}<inlineCode parentName="p">{`--fontsize-heading-xl-mobile`}</inlineCode>{` is meant to be used as the size of a heading level 1 on mobile screens and information-heavy user interfaces. `}</p>
    <p>{`To learn more about the typography usage, please refer to the `}<InternalLink href="/foundation/design-tokens/typography" mdxType="InternalLink">{`Typography design token documentation page.`}</InternalLink></p>
    <h3 {...{
      "id": "visual-changes-to-components",
      "style": {
        "position": "relative"
      }
    }}>{`Visual changes to components`}<a parentName="h3" {...{
        "href": "#visual-changes-to-components",
        "aria-label": "visual changes to components permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Due to the typography change, most of the components have gotten minor visual changes. While these changes are very small, it is good to note that the changes may break your project's regression tests.`}</p>
    <p>{`The following components have had slight visual changes due to the updated typography tokens: `}</p>
    <ul>
      <li parentName="ul">
        <InternalLink href="/components/accordion" mdxType="InternalLink">Accordion</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/card" mdxType="InternalLink">Card</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/dialog" mdxType="InternalLink">Dialog</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/footer" mdxType="InternalLink">Footer</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/linkbox" mdxType="InternalLink">Linkbox</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/navigation" mdxType="InternalLink">Navigation</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/notification" mdxType="InternalLink">Notification</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/stepper" mdxType="InternalLink">Stepper</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/table" mdxType="InternalLink">Table</InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/components/tooltip" mdxType="InternalLink">Tooltip</InternalLink>
      </li>
    </ul>
    <h3 {...{
      "id": "content-width-changes",
      "style": {
        "position": "relative"
      }
    }}>{`Content width changes`}<a parentName="h3" {...{
        "href": "#content-width-changes",
        "aria-label": "content width changes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The container width tokens from `}<inlineCode parentName="p">{`xs`}</inlineCode>{` to `}<inlineCode parentName="p">{`l`}</inlineCode>{` size have been deprecated. From now on, the only container with token used is the `}<inlineCode parentName="p">{`--container-width-xl`}</inlineCode>{` that defined the maximum width of the content area. Below this width, the content area now scales according to the screen size and margins will stay the same.`}</p>
    <p>{`Please refer to the [Breakpoint token documentation page to learn more<InternalLink href="/foundation/design-tokens/breakpoints). Also check out the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" size="M" href="/storybook/react/?path=/story/components-container--example" mdxType="Link">{`HDS Container component`}</Link>{` which makes it easy to follow breakpoint and container width tokens.`}</p>
    <h2 {...{
      "id": "designers",
      "style": {
        "position": "relative"
      }
    }}>{`Designers`}<a parentName="h2" {...{
        "href": "#designers",
        "aria-label": "designers permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification label="A summary for designers" className="siteNotification" mdxType="Notification">
  <ol>
    <li>Get familiar with the visual changes by reading the 2.0 release posts</li>
    <li>Update to the newest HDS libraries in each of your project's Sketch files</li>
    <li>Adapt your typography elements to the new style</li>
    <li>Change your screens to align with the new content width values</li>
  </ol>
    </Notification>
    <p>{`Before updating, we recommend to take a look at the `}<InternalLink href="/getting-started/hds-2.0/what-is-hds-2.0" mdxType="InternalLink">{`What is HDS 2.0 page`}</InternalLink>{` and `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.0.0" mdxType="ExternalLink">{`the official 2.0 changelog`}</ExternalLink>{`. This helps you to get an understanding of what has been changed and what kind of effects updating may have on your design files.`}</p>
    <h3 {...{
      "id": "updating-the-hds-sketch-libraries",
      "style": {
        "position": "relative"
      }
    }}>{`Updating the HDS Sketch libraries`}<a parentName="h3" {...{
        "href": "#updating-the-hds-sketch-libraries",
        "aria-label": "updating the hds sketch libraries permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The newest stable HDS version is always available as an Abstract shared library. If you have already included HDS Sketch libraries in your project, all you need to do is to go to each Sketch file of your project and update libraries to the newest version. If you need help with this process, please refer to our [Abstract tutorial page<InternalLink href="/getting-started/tutorials/abstract-tutorial#how-to-update-hds-libraries).`}</p>
    <h3 {...{
      "id": "updated-typographies",
      "style": {
        "position": "relative"
      }
    }}>{`Updated typographies`}<a parentName="h3" {...{
        "href": "#updated-typographies",
        "aria-label": "updated typographies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note that because typography styles have changed, most of the components will get minor visual changes when you update libraries. After updating your libraries, it is a good time to check your project's typographies. To adapt them to new styles, you need to start using the new heading level 1 font sizes (64px and 40px respectively for desktop and mobile). To learn more about the typography usage, please refer to the [Typography design token documentation page<InternalLink href="/foundation/design-tokens/typography).`}</p>
    <h3 {...{
      "id": "updated-content-width",
      "style": {
        "position": "relative"
      }
    }}>{`Updated content width`}<a parentName="h3" {...{
        "href": "#updated-content-width",
        "aria-label": "updated content width permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Now there is only one maximum value for the content width, 1200 pixels. In other breakpoints, the content container takes all available space while the outside margins stay unchanged. To update your screens to match the new guideline, please check margin values for each breakpoint in the [Breakpoint token documentation page<InternalLink href="/foundation/design-tokens/breakpoints).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      